<template>
  <div>
    <a-card :bordered="false">
      <div class="queryView">
        <!-- <div class="queryView-conditions">
          <a-form layout="inline" :form="form" class="form-label-6">
            <a-form-item>
              <div class="condition-btns">
                <a-button type="primary" @click="doQuery(1)">查询</a-button>
                <a-button type="default" @click="resetAndQuery">重置</a-button>
              </div>
            </a-form-item>
          </a-form>
        </div> -->
        <div class="queryView-actions">
          <a-button icon="plus-circle" @click="add">新增</a-button>
        </div>
        <div class="queryView-main">
          <a-table
            class="queryView-table"
            :rowKey="$useRowIndex"
            bordered
            :loading="loading"
            :columns="tableColumns"
            :dataSource="dataSource"
            :pagination="false"
            size="middle"
          ></a-table>
          <base-pagination
            :totalCount.sync="totalCount"
            :pageNo.sync="pageNo"
            :pageSize.sync="pageSize"
            @change="pageChange"
            size="small"
          />
        </div>
      </div>
    </a-card>
  </div>
</template>

<script>
import { queryViewMixin } from '@/utils/mixin'
export default {
  mixins: [queryViewMixin],

  data() {
    const tableColumns = [
      {
        title: '规则名称',
        width: '10%',
        align: 'center',
        dataIndex: 'ruleName',
      },
      {
        title: '规则类型',
        width: '10%',
        align: 'center',
        dataIndex: 'ruleTypeCN',
      },
      {
        title: '规则编码',
        width: '10%',
        align: 'center',
        dataIndex: 'ruleCode',
      },
      {
        title: '触发场景',
        width: '15%',
        align: 'center',
        dataIndex: 'sceneCN',
      },
      {
        title: '风险等级',
        width: '10%',
        align: 'center',
        dataIndex: 'riskLevel',
        customRender: (text, record) => {
          return (
            <div>
              <span
                class={{
                  'text-red-500': text === '1',
                  'text-yellow-500': text === '2',
                }}
              >
                {record.riskLevelCN}
              </span>
            </div>
          )
        },
      },
      {
        title: '状态',
        width: '12%',
        align: 'center',
        dataIndex: 'status',
        customRender: (text, row, index) => {
          const checked = Number(text) === 1
          return (
            <div>
              <a-switch
                loading={row.switchLoading}
                size="small"
                checked={checked}
                onChange={() => {
                  this.changeStatus(row, Number(!checked), index)
                }}
              />
            </div>
          )
        },
      },
      {
        title: '操作',
        width: '10%',
        align: 'center',
        customRender: (text, record) => {
          return (
            <a
              onClick={() => {
                this.edit(record)
              }}
            >
              详情
            </a>
          )
        },
      },
    ]
    return {
      form: this.$form.createForm(this),
      pageNo: 1,
      pageSize: 10,
      totalCount: 0,
      loading: false,
      tableColumns: tableColumns,
      dataSource: [],
    }
  },
  computed: {
    typesMap() {
      return {}
    },
  },
  methods: {
    async doQuery(_pageNo = this.pageNo, _pageSize = this.pageSize) {
      this.loading = true
      try {
        const params = this.getPageParams(_pageNo, _pageSize)
        const res = await this.$axios.post('/aie/web/rule/list', {
          ...params,
        })
        const list = res.data.list
        const { pageSize, pageNo, totalCount } = res.data
        this.totalCount = totalCount
        this.pageSize = pageSize
        this.pageNo = pageNo
        this.dataSource = list
      } catch (e) {
        console.log(e)
      }
      this.loading = false
    },
    add() {
      this.$router.push({
        path: '/m/aie/rule/ruleDetail',
      })
    },
    edit(record) {
      this.$router.push({
        path: '/m/aie/rule/ruleDetail',
        query: {
          id: record.id,
        },
      })
    },
    async changeStatus(row, status, index) {
      this.$set(this.dataSource[index], 'switchLoading', true)
      try {
        await this.$axios.post('/aie/web/rule/update/status', {
          id: row.id,
          status,
        })
        this.$message.success('操作成功!')
        this.doQuery()
      } catch (e) {}
      this.$set(this.dataSource[index], 'switchLoading', false)
    },
  },
  async mounted() {
    this.doQuery()
  },
}
</script>

<style lang="less" scoped></style>
